@font-face {
    font-family: 'ArialRounded';
    src: local('ArialRounded'), url(./fonts/arlrdbd.ttf) format('truetype');
}

@font-face {
    font-family: 'Poppins';
    src: local('Poppins'), url(./fonts/Poppins-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Heebo';
  src: local('HeeboBold'), url(./fonts/Heebo-Bold.ttf) format('truetype');
  src: local('HeeboRegular'), url(./fonts/Heebo-Regular.ttf) format('truetype');
}

body, html {
    padding: 0;
    margin: 0;
    width: 100%;
    font-family: ArialRounded;
}
